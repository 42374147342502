/* You can add global styles to this file, and also import other style files */
@import "./custom_theme.scss";
@import '~bootstrap/scss/bootstrap';
@import '~ngx-toastr/toastr-bs4-alert';
@import "~ngx-popperjs/css/theme-dark.css";
@import "./assets/fontawesome_pseudo/scss/fontawesome.scss";
@import "./assets/fontawesome_pseudo/scss/solid.scss";
@import "./assets/fontawesome_pseudo/scss/regular.scss";
@import "./assets/fontawesome_pseudo/scss/brands.scss";
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@700&display=swap');

@import "~@ng-select/ng-select/themes/default.theme.css";
@import '~@angular/material/theming';
@include mat-core();

$bg-traiy-blue: #478BF5;
$text-color:#6C6565;
$text-darkcolor:#4a4849;
$text-lightcolor: #bcbcbc;
$increase-bg: #e1eaf9;
$increase-text: #4f90f5;
$decrease-bg:#fef0f4;
$decrease-text: #f34f77;

html, body { height: 100%; }
body { color:$text-color;margin: 0; font-family: 'Mulish', 'Arial'; font-style:normal!important; }
p,h1,h2,h3,h4,h5,h6,ul,label{ margin-bottom: 0;}
.mat-body, .mat-body-1, .mat-typography,
.mat-h1, .mat-headline, .mat-typography h1, .mat-typography h2{
  font-family: 'Mulish', sans-serif!important;
}

$colors: (
  /*green: #77b62d,
  red: #e6182d,
  yellow: #ffae17,
  blue: #3072eb,
  turqoise: #4ebcb4,
  seafoam: #009acb,
  purple: #7369df,
  orange: #f36c20*/
  green: #77b62d,
  red: #e6182d,
  seafoam: #009acb,
  blue: #3072eb,
  turqoise: #4ebcb4,
  purple: #7369df,
  yellow: #ffae17,
  orange: #f36c20
);

$member_colors: (
  aqua: #43BFB9,
  purple: #736adf,
  blue: #478BF5
);

$advice_colors: (
  purple: #7369DF,
  red: #F16063,
  orange: #F6A73E,
  green: #94CC4D,
);

$advice_bgcolors: (
  purple: #EAE9FA,
  red: #FDE7E8,
  orange: #FDEDD8,
  green: #EAF5DB,
);

$advice_member_colors: (
  green: #e3f5f5,
  purple: #eae9f9,
  red: #fde7e9,
  orange: #fdecd8
);

$advice_member_colors_txt: (
  green: #53c7c1,
  purple: #7f77e2,
  red: #f2686b,
  orange: #f6aa46
);

$insights_buttons_colors: (
  day: #90cb45,
  week: #F6A73E,
  month: #4B8DF2,
  quarter: #EB4E69,
  year: #7369DF
);

$border-color:#e6e6e6;
$bg-gray:#fdfdfd;


.member_panel{
  background-color: #f5f5f5;
}

.loader{ // skeleton color
  background: no-repeat #eaeaea!important;
  margin-bottom: 0 !important;
}

.btn{
  border-width: 2px!important;
  font-weight: 500!important;
}

.progress-bar.bg-info{
  background-color:$bg-traiy-blue!important;
}

.profileBackground {
  background-color: #bbbbbbf2;
  backdrop-filter: blur(4px);
}

.profilePanel {
  background-color: white;
}

button:hover,
button:focus{
  outline: none!important;
}

li.page-item{
  .page-link{
    background-color: #fafafa;
  }
  &:not(:first-child):not(:last-child){
    color: $text-color;
  }
  &.active .page-link{
    background-color: $bg-traiy-blue!important;
    border-color: $bg-traiy-blue!important;
  }
  a[aria-label='Previous'] {
    span {
        display: none !important;
    }

    &::before {
      @extend %fa-icon;
      @extend .fas;
      content: fa-content($fa-var-chevron-left);
    }
  }

  a[aria-label='Next'] {
    span {
        display: none !important;
    }

    &::before {
      @extend %fa-icon;
      @extend .fas;
      content: fa-content($fa-var-chevron-right);
    }
  }
}

.errors{
  color: red;
  font-size: 13px;
  padding: 0 10px;
  position: relative;
  top: -18px;
  margin-bottom: 10px;
}

mark{
  background-color: #F6A73E!important;
  font-weight: 600;
  padding: 0!important;
}

.daterange-label{
  display: flex;
  align-items: center;
  font-weight: 600;
}

.daterange-input{
  display: flex;
  align-items: center;
  color: white;
  font-weight: 200;
  text-transform: uppercase;
  padding: 0;
  &::before{
    content: url(./assets/img/calendar.svg);
    color: white;
    position: absolute;
    top: 12px;
    left: 12px!important;
  }
  &::after{
    content: url(./assets/img/calendar-arrow.svg);
    color: white;
    position: absolute;
    font-size: 12px;
    right: 20px;
  }
  .daterange{
    background-color: $bg-traiy-blue;
    padding: 20px 30px 20px 35px;
    color: white;
    cursor: pointer!important;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    &::placeholder {
      color: white;
      font-size: 12px;
      font-weight: 400;
      text-transform: uppercase;
      opacity: 1; /* Firefox */
    }
  }
}

.md-drppicker{
  font-family: 'Mulish', sans-serif!important;
  &.ltr {
    top: auto;
    right: 0!important;
    left: auto!important;
  }
  .btn,.btn:focus,.btn:hover{
    background-color: $bg-traiy-blue!important;
  }
}

::ng-deep{
  .md-drppicker{
    color: $text-color!important;
    font-weight: 400;
    td.active,
    td.active:hover{
      background-color: $bg-traiy-blue!important;
    }
    td.off.end-date{
      color: black!important;
      font-weight: 500;
    }
    .ranges ul li button{
      color: $text-color!important;
      font-weight: 700;
      &.active{
        color: white!important;
        background-color: $bg-traiy-blue!important;
      }
    }
    .btn{
      box-shadow: 0 1px 4px rgba(0,0,0,.6)!important;
      :not(.clear){
        &:focus, &:hover{
          background-color: $bg-traiy-blue!important;
        }
      }
      &.clear, &.btn-default{
        background-color: $decrease-text!important;
        border-radius: 5px;
        color: white!important;
        padding: 0 9px!important;
        svg{ display: none;}
        &:focus, &:hover{
          background-color: $decrease-text!important;
          color:white!important;
        }
      }
    }
  }
}

th[sortable].asc:before{
  @extend %fa-icon;
  @extend .fas;
  content: fa-content($fa-var-arrow-up);
  cursor: pointer;
  margin-right: 5px;
}

th[sortable].desc:before {
  @extend %fa-icon;
  @extend .fas;
  content: fa-content($fa-var-arrow-down);
  cursor: pointer;
  margin-right: 5px;
}

div.loading{
  display: flex;
  font-size: 18px;
  font-weight: 500;
  position: relative;
  background-color: rgba(255,255,255,0.5);
  right: 0;
  left: 0;
  height: 100%;
  min-height: 100px;
  align-items: center;
  justify-content: center;
  z-index: 1;
  fa-icon{
    color: $bg-traiy-blue;
  }
}
.circle-notch{
  color: $bg-traiy-blue;
}

@media (min-width: 768px) {
  .row.equal {
    display: flex;
    flex-wrap: wrap;
    .col{
      height: 100%;
    }
  }
}

popper-content {
  display: inline!important;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
}

div.ngxp__container > .ngxp__arrow:before {
  display: none;
}

.score-details-dialog {
  justify-content: normal !important;
  align-items: normal !important;
  position: absolute !important;
  top: 150px;
  left: 60vw;
}

.mat-time-theme {
  background: #478bf5 !important;
}